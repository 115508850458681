<template>
  <el-form
    ref="explanationReasonFormRef"
    v-loading="loading"
    :model="explanationReasonForm"
    :rules="rules"
    label-position="top"
    class="elv-reconciliation-explain-form"
    @submit.prevent
  >
    <el-form-item :label="transformI18n(props.reasonLabel)" prop="reconciliationReasonId">
      <ElvSelect
        v-model="explanationReasonForm.reconciliationReasonId"
        :placeholder="`${t('common.select')}...`"
        filterable
        allow-create
        :disabled="props.disableSave"
        default-first-option
        :reserve-keyword="false"
        :options="reasonList"
        :popper-append-to-body="false"
      />
    </el-form-item>

    <el-form-item :label="t('common.description')" class="elv-reconciliation-explain-form-journal__desc">
      <el-input
        v-model="explanationReasonForm.description"
        :rows="3"
        type="textarea"
        placeholder=" "
        :disabled="props.disableSave"
      />
    </el-form-item>

    <template
      v-if="
        createJournal ||
        (props.model === 'edit' &&
          ((props.currentData?.journalActivity?.journalNo && ['KEEP'].includes(explanationReasonForm.journalAction)) ||
            (props.dataType === 'AUTOMATION' && createJournal)))
      "
    >
      <div class="elv-reconciliation-explain-form-journal__info-title">
        <span>{{ transformI18n(props.adjustmentJournalTitle) }}</span>
        <div class="line"></div>
      </div>
      <template
        v-if="!['KEEP', 'DELETE'].includes(explanationReasonForm.journalAction) || props.dataType === 'AUTOMATION'"
      >
        <div
          class="elv-reconciliation-explain-form-journal__info"
          :class="{ 'is-longer': props.dataType === 'AUTOMATION' }"
        >
          <el-form-item :label="t('title.counterpartyAccount')" prop="chartOfAccountId">
            <ChartOfAccountOverlayDropdown
              v-model="explanationReasonForm.chartOfAccountId"
              :class="{
                'is-shorter-entry':
                  explanationReasonForm.chartOfAccountId && currentChartOfAccount?.auxiliaryTypes?.length
              }"
              @onChangeChartOfAccount="onChangeChartOfAccount"
            />
          </el-form-item>
          <template v-if="currentChartOfAccount?.auxiliaryTypes?.length">
            <el-form-item
              v-for="(auxiliaryValue, i) in currentChartOfAccount?.auxiliaryTypes"
              :key="i"
              :label="`${t('report.auxiliaryCode')} / ${auxiliaryValue?.name}`"
            >
              <ElvSelect
                v-if="auxiliaryValue?.type === 'ENTITY_ACCOUNT'"
                v-model="explanationReasonForm.auxiliaryValueList[i].entityAccountId"
                clearable
                class="is-shorter-entry"
                width="206px"
                :placeholder="t('placeholder.selectAccount')"
                :options="accountNormalOptions"
                :collapse-list="accountDeletedOptions"
              >
                <template #emptyContent>
                  <div class="elv-transactions-add-journal-form-account-item__empty">
                    <svgIcon name="zoom-area" width="24" height="24" />
                    <p>{{ t('message.noAccountFound') }}</p>
                    <router-link :to="{ name: 'entity-accounts-treasury' }">{{
                      t('button.goToAddAccount')
                    }}</router-link>
                  </div>
                </template>
              </ElvSelect>
              <el-select
                v-else-if="auxiliaryValue?.type === 'COUNTERPARTY'"
                v-model="explanationReasonForm.auxiliaryValueList[i].counterpartyId"
                filterable
                remote
                clearable
                reserve-keyword
                class="is-shorter-entry"
                :loading="searchLoading"
                :remote-method="remoteCounterpartyMethod"
                :placeholder="t('placeholder.enterNameForSearch')"
                popper-class="elv-counterparty-addTo-dialog-popper"
              >
                <el-option
                  v-for="counterparty in counterpartyOptions"
                  :key="counterparty.counterpartyId"
                  :label="counterparty.name"
                  :value="counterparty.counterpartyId"
                />
              </el-select>

              <el-select
                v-else-if="auxiliaryValue?.type === 'ITEM'"
                v-model="explanationReasonForm.auxiliaryValueList[i].auxiliaryItemId"
                filterable
                remote
                clearable
                class="is-shorter-entry"
                :placeholder="`${t('common.select')}...`"
                popper-class="elv-counterparty-addTo-dialog-popper"
              >
                <el-option
                  v-for="auxiliaryItem in auxiliaryValue?.auxiliaryItems"
                  :key="auxiliaryItem.auxiliaryItemId"
                  :label="auxiliaryItem.value"
                  :value="auxiliaryItem.auxiliaryItemId"
                />
              </el-select>
            </el-form-item>
          </template>
        </div>
        <el-form-item :label="t('report.memo')" class="elv-reconciliation-explain-form-journal__memo">
          <el-input v-model="explanationReasonForm.memo" placeholder=" " />
        </el-form-item>
      </template>

      <template v-else>
        <div class="elv-reconciliation-explain-form-journal-detail">
          <div class="elv-reconciliation-explain-form-journal-detail-item__type">
            <p>{{ props.currentData?.journalActivity?.journalType?.name }}</p>
            <span>{{ props.currentData?.journalActivity?.journalNo }}</span>
          </div>
          <ol>
            <li>
              <span class="elv-reconciliation-explain-form-journal-detail-item-entry__direction title" />
              <p class="elv-reconciliation-explain-form-journal-detail-item-entry__account title">
                {{ t('common.account') }}
              </p>
              <div class="elv-reconciliation-explain-form-journal-detail-item-entry__debit title">
                {{ t('common.debit') }}
              </div>
              <div class="elv-reconciliation-explain-form-journal-detail-item-entry__credit title">
                {{ t('common.credit') }}
              </div>
            </li>
            <li
              v-for="item in props.currentData?.journalActivity?.entryList"
              :key="item?.journalEntryId"
              :class="{ 'is-matched': item?.matched }"
            >
              <span class="elv-reconciliation-explain-form-journal-detail-item-entry__direction">{{
                item?.balanceType
              }}</span>
              <div class="elv-reconciliation-explain-form-journal-detail-item-entry__account">
                {{ item?.chartOfAccount?.name }}
                <div
                  v-if="item?.auxiliaryValueList?.length"
                  class="elv-reconciliation-explain-form-journal-detail-item-entry__auxiliary-list"
                >
                  <template v-for="auxiliaryValue in item.auxiliaryValueList" :key="auxiliaryValue.auxiliaryTypeId">
                    <div
                      v-if="getAuxiliaryValue(auxiliaryValue)"
                      class="elv-reconciliation-explain-form-journal-detail-item-entry__account-auxiliary"
                    >
                      {{ getAuxiliaryValue(auxiliaryValue) }}
                    </div>
                  </template>
                </div>
              </div>
              <p class="elv-reconciliation-explain-form-journal-detail-item-entry__debit">
                <template v-if="item?.balanceType === 'Dr'">
                  <p
                    :title="`${fieldValueFormat(
                      item?.amountFC,
                      {
                        price: true,
                        keepPoint: true,
                        symbol: `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      },
                      'NUMBER'
                    )}`"
                  >
                    {{
                      formatNumberToSignificantDigits(
                        item?.amountFC,
                        2,
                        `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      )
                    }}
                  </p>
                  <span
                    :title="`${formatNumber(item?.amount, 9)} ${
                      item?.underlyingCurrency?.showSymbol ?? item?.currency
                    }`"
                    >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                    {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                  >
                </template>
                <template v-else>-</template>
              </p>
              <p class="elv-reconciliation-explain-form-journal-detail-item-entry__credit">
                <template v-if="item?.balanceType === 'Cr'">
                  <p
                    :title="`${fieldValueFormat(
                      item?.amountFC,
                      {
                        price: true,
                        keepPoint: true,
                        symbol: `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      },
                      'NUMBER'
                    )}`"
                  >
                    {{
                      formatNumberToSignificantDigits(
                        item?.amountFC,
                        2,
                        `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      )
                    }}
                  </p>
                  <span
                    :title="`${formatNumber(item?.amount, 9)} ${
                      item?.underlyingCurrency?.showSymbol ?? item?.currency
                    }`"
                    >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                    {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                  >
                </template>
                <template v-else>-</template>
              </p>
            </li>
          </ol>
        </div>
      </template>

      <div
        v-if="!props.disableSave"
        class="elv-reconciliation-explain-form-journal__remove"
        @click="onRemoveReconciliationJournal"
      >
        <SvgIcon name="common-close" width="16" height="16" fill="#636B75" />{{
          t('button.removeReconciliationAdjustmentJournal')
        }}
      </div>
    </template>

    <div
      v-if="
        (!createJournal && props.dataType !== 'TRANSACTION' && explanationReasonForm.journalAction !== 'KEEP') ||
        (!new Big(props?.difference ?? '0').eq(0) &&
          !props.currentData?.journalActivity?.journalNo &&
          !props.disableSave)
      "
      class="elv-reconciliation-explain-form-journal__add"
      @click="onCreateReconciliationJournal"
    >
      <SvgIcon name="add-default" width="16" height="16" fill="#1753eb" />{{
        t('button.createReconciliationAdjustmentJournal')
      }}
    </div>
  </el-form>
</template>

<script setup lang="ts">
import Big from 'big.js'
import { find } from 'lodash-es'
import AccountsApi from '@/api/AccountsApi'
import { $t, transformI18n } from '@/i18n/index'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
// eslint-disable-next-line no-unused-vars
import type { FormInstance, FormRules } from 'element-plus'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import { formatNumber, fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'
import ChartOfAccountOverlayDropdown from '../../components/ChartOfAccountOverlayDropdown.vue'

const props = defineProps({
  model: {
    type: String, // 'edit' | 'add'
    required: true
  },
  dataType: {
    type: String,
    required: true
  },
  disableSave: {
    type: Boolean,
    default: false
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  difference: {
    type: String,
    default: '0'
  },
  reasonLabel: {
    type: String,
    default: $t('reconciliation.reason')
  },
  adjustmentJournalTitle: {
    type: String,
    default: $t('reconciliation.createReconciliationAdjustmentJournal')
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const reconciliationStore = useReconciliationStore()
const { entityDetail } = storeToRefs(entityStore)
const explanationReasonFormRef = useTemplateRef<FormInstance>('explanationReasonFormRef')

interface ExplanationReasonFormType {
  reconciliationReasonId: string
  name: string
  description: string
  chartOfAccountId: string
  auxiliaryValueList: any[]
  memo: string
  journalAction: string // 'KEEP/CREATE/DELETE'
}

const loading = ref(false)
const createJournal = ref(false)
const searchLoading = ref(false)
const counterpartyOptions: any = ref([])
const originCounterpartyOptions: any = ref([])

const explanationReasonForm = defineModel<ExplanationReasonFormType>({ required: true })

const rules = reactive<FormRules>({
  reconciliationReasonId: {
    trigger: 'blur',
    required: true,
    message: 'Please select a reason'
  },
  chartOfAccountId: {
    trigger: 'blur',
    required: true,
    message: 'Please select an account'
  }
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item: any) => {
    return item.status === 'NORMAL'
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform.logo,
      isCheck: false
    }
  })
})

const accountDeletedOptions = computed(() => {
  const accountList = accountStore.accountList
    .filter((item: any) => {
      return item.status !== 'NORMAL'
    })
    .map((item: any) => {
      return {
        id: item.entityAccountId,
        value: item.entityAccountId,
        label: item.name,
        icon: item.platform.logo,
        isCheck: false
      }
    })
  if (accountList.length) {
    return [
      {
        title: t('title.deletedAccounts'),
        list: accountList
      }
    ]
  }
  return []
})

const reasonList = computed(() => {
  return reconciliationStore.reconciliationTaskReasonList.map((item) => {
    return {
      label: item.name,
      value: item.reconciliationReasonId
    }
  })
})

const currentChartOfAccount = computed(() => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId: explanationReasonForm.value?.chartOfAccountId })
})

const getAuxiliaryValue = (item: any) => {
  if (item?.auxiliaryType?.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item?.auxiliaryType?.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }

  if (item?.auxiliaryType?.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}

const onCreateReconciliationJournal = () => {
  createJournal.value = true
  explanationReasonForm.value.journalAction = 'CREATE'
  explanationReasonForm.value.memo = 'Reconciliation Journal'
}

const onRemoveReconciliationJournal = () => {
  createJournal.value = false
  if (props.model === 'edit') {
    explanationReasonForm.value.journalAction = 'DELETE'
  }
  explanationReasonForm.value.chartOfAccountId = ''
  explanationReasonForm.value.auxiliaryValueList = []
  explanationReasonForm.value.memo = ''
}

/**
 * @description: 远程搜索Counterparty
 * @param {string} query
 */
const remoteCounterpartyMethod = async (query: string) => {
  if (query) {
    try {
      searchLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

const onChangeChartOfAccount = () => {
  explanationReasonForm.value.auxiliaryValueList = []
  explanationReasonForm.value.auxiliaryValueList = currentChartOfAccount.value?.auxiliaryTypes?.length
    ? currentChartOfAccount.value?.auxiliaryTypes.map((item: any) => {
        const auxiliaryType: any = {
          auxiliaryTypeId: item.auxiliaryTypeId
        }
        if (item.type === 'ENTITY_ACCOUNT') {
          auxiliaryType.entityAccountId = ''
        } else if (item.type === 'COUNTERPARTY') {
          auxiliaryType.counterpartyId = ''
        } else if (item.type === 'ITEM') {
          auxiliaryType.auxiliaryItemId = ''
        }
        return auxiliaryType
      })
    : []
}

const initSelectData = async () => {
  try {
    loading.value = true
    const counterpartyDataList = new Promise((resolve, reject) => {
      AccountsApi.getCounterpartyList(entityId.value, { limit: 20, page: 1 })
        .then((res) => {
          counterpartyOptions.value = res.data.list
          originCounterpartyOptions.value = res.data.list
          return resolve(res.data)
        })
        .catch((e) => {
          return reject(e)
        })
    })
    const requestList = [
      reconciliationStore.fetchReconciliationTaskReasonList(entityId.value, reconciliationTaskId.value),
      counterpartyDataList,
      accountStore.fetchEntityAccountList(entityId.value)
    ]
    await Promise.allSettled(requestList)
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  initSelectData()
})

watch(
  [() => props.model, () => props.currentData],
  () => {
    if (props.model === 'edit') {
      explanationReasonForm.value.reconciliationReasonId =
        props.currentData.reconciliationReasonId || props.currentData?.reconciliationReason?.reconciliationReasonId
      explanationReasonForm.value.description =
        props.currentData.description || props.currentData?.reconciliationExplain?.description
      if (props.dataType === 'AUTOMATION') {
        explanationReasonForm.value.chartOfAccountId = props.currentData.chartOfAccountId
        explanationReasonForm.value.memo = props.currentData.memo
        explanationReasonForm.value.auxiliaryValueList = props.currentData.auxiliaryValueList
        if (
          explanationReasonForm.value.auxiliaryValueList?.length ||
          explanationReasonForm.value.memo ||
          explanationReasonForm.value.chartOfAccountId
        ) {
          createJournal.value = true
        } else {
          createJournal.value = false
        }
      } else {
        explanationReasonForm.value.journalAction = 'KEEP'
      }
    }
  },
  {
    immediate: true
  }
)

defineExpose({
  createJournal,
  explanationReasonFormRef
})
</script>

<style lang="scss">
.elv-reconciliation-explain-form {
  width: 100%;

  .elv-reconciliation-explain-form-journal__info-title {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    margin-top: 8px;

    span {
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
      flex-shrink: 0;
    }

    .line {
      border-bottom: 1px solid #edf0f3;
      flex-grow: 1;
    }
  }

  .elv-reconciliation-explain-form-journal__info {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;

    &.is-longer {
      gap: 8px 16px;

      .elv-journal-coa-dropdown-content {
        width: 1152px;

        &.is-shorter-entry {
          width: 568px;
        }
      }

      .is-shorter-entry.elv-base-extend-select {
        width: 568px;
      }
    }

    .el-form-item {
      margin-bottom: 0 !important;
    }

    .elv-journal-coa-dropdown-content {
      width: 572px;

      &.is-shorter-entry {
        width: 278px;
      }
    }

    .is-shorter-entry.elv-base-extend-select {
      width: 278px;
    }
  }

  .el-form-item {
    &.elv-reconciliation-explain-form-journal__memo,
    &.elv-reconciliation-explain-form-journal__desc {
      margin-bottom: 0px !important;
    }

    &.elv-reconciliation-explain-form-journal__desc {
      .el-textarea.is-disabled .el-textarea__inner {
        color: #0e0f11;
      }
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
    }
  }

  .el-select {
    width: 100%;

    .el-select__wrapper {
      width: 100%;
    }
  }

  .elv-reconciliation-explain-form-journal__add {
    display: flex;
    align-items: center;
    color: #1753eb;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;

    &:not(.is-disabled):hover {
      cursor: pointer;
    }

    .is-disabled {
      cursor: not-allowed;
    }

    svg {
      margin-right: 4px;
    }
  }

  .elv-reconciliation-explain-form-journal-detail {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;

    .elv-reconciliation-explain-form-journal-detail-item__type {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 10px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #dde1e6;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 8px;
      position: relative;

      p {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        max-width: 75%;
      }

      span {
        color: #838d95;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
      }
    }

    ol {
      background: #ffffff;
      border: 1px solid #ced7e0;
      border-radius: 4px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #0e0f11;

      li {
        min-height: 40px;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: stretch; /* 子元素的高度将与父元素相匹配 */
        justify-content: center;
        box-sizing: border-box;
        border-top: 1px solid #edf0f3;

        &:first-child {
          min-height: 28px;
          border-top: 0px;
        }
      }

      .elv-reconciliation-explain-form-journal-detail-item-entry__direction {
        min-height: 40px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.title {
          min-height: 28px;
        }
      }

      .elv-reconciliation-explain-form-journal-detail-item-entry__account {
        width: 393px;
        min-height: 40px;
        height: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
        border-right: 1px solid #edf0f3;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 5px;

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }

        .elv-reconciliation-explain-form-journal-detail-item-entry__auxiliary-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          gap: 4px;
        }

        .elv-reconciliation-explain-form-journal-detail-item-entry__account-auxiliary {
          box-sizing: border-box;
          height: 19px;
          padding: 0px 9px;
          border-radius: 16px;
          max-width: 136px;
          border: 1px solid #dde1e6;
          background: #f9fafb;
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .elv-reconciliation-explain-form-journal-detail-item-entry__debit {
        border-right: 1px solid #edf0f3;
      }

      .elv-reconciliation-explain-form-journal-detail-item-entry__debit,
      .elv-reconciliation-explain-form-journal-detail-item-entry__credit {
        width: 75px;
        min-height: 40px;
        padding-right: 10px;
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        word-break: break-word;
        text-align: right;

        span {
          color: #838d95;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          white-space: nowrap;
          overflow: hidden;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }
      }
    }
  }

  .elv-reconciliation-explain-form-journal__remove {
    display: flex;
    align-items: center;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;

    &:not(.is-disabled):hover {
      cursor: pointer;
    }

    .is-disabled {
      cursor: not-allowed;
    }

    svg {
      margin-right: 4px;
    }
  }
}
</style>
